<template>
  <div>
    <b-modal
      ref="website-leads-modal"
      modal-class="custom-modal-amg"
      title-class="h3 text-white"
      size="xl"
      header-bg-variant="transparent"
      header-class="p-0"
      @hidden="closeModal()"
      centered
      hide-footer
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>WEBSITE LEADS</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModal()"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <WebsiteLeads />
    </b-modal>
  </div>
</template>
<script>
import WebsiteLeads from "@/views/crm/views/Lead/lead-module/list/WebsiteLeads.vue";
export default {
  name: "WebsiteLeadsModal",
  props: {},
  components: {
    WebsiteLeads,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  async mounted() {
    this.toggleModal("website-leads-modal");
  },
  async created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
